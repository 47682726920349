// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800)
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,400italic,300italic,700,700italic)
@import url(https://fonts.googleapis.com/css?family=Nunito:400,300,700)

@import colors
@import vars
@import reset
@import breakpoints

@import embla
@import dialogOverrides
@import overlayGallery
@import header
@import navMobileMenu
@import section
@import hero
@import heroFooter
@import schedule
@import sectionHeader
@import ourLife
@import dressCodeDialog
@import giftsDialog
@import location
@import rsvpManager
@import beThereForm
@import inputWithIcon
@import partyMember
@import buttons
@import helloThere
@import contactUs

html
  font-family: 'Open Sans', sans-serif

body
  font-family: 'Open Sans', sans-serif

.no-scroll
  overflow-y: hidden

.page-wrapper
  &--locked
    height: 100vh
    overflow-y: hidden
