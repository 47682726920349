.primary-button-wrapper
  .MuiButton-outlined
    height: 48px
    color: $main-pink !important
    border: 1px solid $main-pink !important
    background-color: white
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)

    &:hover
      background-color: rgba(255, 0, 127, 0.04) !important

.secondary-button-wrapper
  .MuiButton-contained
    height: 48px
    border: 1px solid $main-pink !important
    background-color: $main-pink !important
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)
