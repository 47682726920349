.hero-footer
  width: 100%
  height: 18vh
  background-color: white
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

  @media ( max-width: $layout-tablet  )
    min-height: 180px

.getting-married
  text-transform: uppercase
  font-weight: 700
  font-size: 18px
  letter-spacing: 3px
  margin-bottom: 25px
  text-align: center

.date-married
  font-size: 15px
  font-weight: 300
  text-align: center
  line-height: 22px
