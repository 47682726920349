.section-wrapper
  width: 100%
  display: flex
  justify-content: center
  scroll-margin-top: 60px
  position: relative

.section
  width: 100%
  max-width: 1140px

  @media ( max-width: $layout-screen  )
    padding-left: 24px
    padding-right: 24px

.section-full
  max-width: unset
  padding: 0

.wrapper-white
  background-color: white

.wrapper-grey
  background-color: #F8F9FD
