.schedule
  padding-top: 65px
  padding-bottom: 35px

  &__header
    font-size: 18px
    font-weight: 700
    text-transform: uppercase
    letter-spacing: 3px
    margin-bottom: 52px
    text-align: center

  &__days
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-bottom: 40px

    @media ( min-width: $layout-screen  )
      flex-direction: row

  &__dress-code
    width: 100%
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    padding-bottom: 10px

    @media ( min-width: $layout-screen  )
      flex-direction: row

  &__gifts-button
    margin-top: 15px !important

    @media ( min-width: $layout-screen  )
      flex-direction: row
      margin-left: 15px !important
      margin-top: unset !important

.schedule-day
  width: 100%
  padding: 20px
  padding-top: 30px
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)
  border-radius: 15px
  background-color: white
  margin-bottom: 25px

  @media ( min-width: $layout-screen  )
    width: 25%
    margin-left: 15px

  &--first
    margin-left: 0

  &--subtitle
    font-size: 20px
    font-weight: 700
    margin-bottom: 25px
    text-align: center

  &--wedding-day
    border: 2px solid $main-pink

  .date
    font-size: 15px
    font-weight: 700
    margin-bottom: 25px
    text-align: center
    border-bottom: 1px solid $main-pink
    padding-bottom: 18px

  .events
    @media ( max-width: $layout-screen  )
      margin-bottom: 50px

  .event
    margin-bottom: 35px

    .title-and-date
      font-weight: 500
      margin-bottom: 20px

      .title
        font-size: 15px
        text-transform: uppercase
        line-height: 21px

      .event-date
        font-size: 13px
        font-style: italic
        text-transform: lowercase
        color: rgba(28,54,83,.6)
        padding-top: 5px
        white-space: nowrap

    .description
      font-size: 15px
      line-height: 20px
