.contact-us
  padding-bottom: 50px

  &__contact-info
    text-align: center
    margin-bottom: 30px
    line-height: 24px

  &__in-case
    margin-bottom: 10px

  &__phone
    font-weight: 700

  &__separator-parent
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    margin-bottom: 35px

  &__circle
    display: flex
    flex-shrink: 0
    width: 50px
    height: 50px
    border-radius: 50%
    border: 1px solid #e6e9ea
    z-index: 1
    background-color: white

  &__line
    height: 1px
    width: 100%
    background-color: #e6e9ea
    position: absolute
    top: 25px

  &__crafted
    width: 100%
    text-align: center
