.mobile-nav-wrapper
  height: 0
  width: 0
  overflow: hidden
  position: absolute

  @media ( min-width: $layout-tablet  )
    display: none

  &--open
    top: 0
    left: 0
    width: 100vw
    height: 100vh

.mobile-menu
  height: 100%
  padding: 24px
  opacity: 0
  display: flex
  flex-direction: column
  align-items: center
  transition: all 250ms
  transition-delay: 250ms
  background-color: rgba(55, 36, 40, 0.98)

  &--open
    opacity: 1

  &__nav
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-end

  &__nav-link
    width: 100%
    font-size: 18px
    font-weight: 600
    letter-spacing: 1px
    text-transform: uppercase
    color: white
    cursor: pointer
    padding: 12px
    margin-bottom: 8px
    text-align: center

  &__close-button
    padding: 30px
    text-align: center
    font-size: 18px
    font-weight: 600

  &__logo
    @media ( max-width: $layout-tablet  )
      padding-top: 20px
      width: 90%
      object-fit: cover
      margin-bottom: 40px
