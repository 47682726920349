.header
  top: 0
  position: fixed
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  transition: all 300ms ease-in-out
  z-index: 1000

  &--grey
    background-color: rgba(55,36,40,.88)

  &__inner-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2)
    padding: 32px 0
    transition: padding 300ms ease-in-out

    @media ( max-width: $layout-tablet  )
      padding: 22px 0

    &--crunched
      padding: 22px 0
      border-bottom: unset

  &__logo
    height: 50px
    object-fit: cover

  &__nav-burger
    @media ( min-width: $layout-tablet  )
      display: none

  &__burger-icon
    display: none
    color: white
    cursor: pointer

  &__nav-wrapper
    @media ( max-width: $layout-tablet  )
      display: none

  &__link-list
    display: flex

  &__nav-link
    font-size: 13px
    font-weight: bold
    letter-spacing: 0.6px
    text-transform: uppercase
    padding-right: 25px
    color: white
    cursor: pointer

    &--last
      padding-right: 0

    &--hidden
      display: none
