.embla
  overflow: hidden

  &__container
    display: flex
    backface-visibility: hidden

    @media ( max-width: $layout-tablet  )
      height: 400px

  &__slide
    flex: 0 0 50%
    min-width: 0
    margin-left: 15px
    position: relative
    border-radius: 12px
    overflow: hidden
    border: 1px solid $main-pink

    @media ( max-width: $layout-tablet  )
      flex: 0 0 92%
      pointer-events: none

    img
      display: block
      width: 100%
      height: 100%
      object-fit: cover

  .img4
    flex: 0 0 23%

    @media ( max-width: $layout-tablet  )
      flex: 0 0 92%

  .img5
    flex: 0 0 23%

    @media ( max-width: $layout-tablet  )
      flex: 0 0 92%

  .img6
    flex: 0 0 23%

    @media ( max-width: $layout-tablet  )
      flex: 0 0 92%
