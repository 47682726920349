.manager
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  &__indicator
    width: 100%
    max-width: 600px
    font-weight: 600
    margin-top: 15px
    height: 45px
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center

    &--sending
      color: #2498bf
      border: 2px solid #2498bf
      background-color: #d5efee

    &--success
      color: #21ad46
      border: 2px solid #21ad46
      background-color: #d2f7dc

    &--failure
      color: #d61402
      border: 2px solid#d61402
      background-color: #fcd5d2

  &__paper
    width: 100%
    max-width: 600px
    padding: 2px
    background-color: white
    color: rgba(0, 0, 0, 0.87)
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)
    border-radius: 12px
    padding: 23px
    padding-top: 35px
    text-align: center
    margin-bottom: 15px

  &__title
    width: 100%
    color: $main-pink
    font-size: 18px
    font-weight: 700
    text-align: center
    padding-bottom: 35px

  &__you
    margin-bottom: 20px

  &__person
    color: $main-pink
    font-size: 16px
    font-weight: 700
    padding-bottom: 30px

  &__row
    font-size: 16px
    color: rgba(28, 54, 83, 0.7)
    padding-bottom: 13px

  &__name
    font-weight: 600

  &__member
    padding-bottom: 15px
