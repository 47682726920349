.dress-code
  min-width: 600px
  padding: 30px
  padding-top: 45px

  @media ( max-width: $layout-tablet  )
    min-width: unset

  &__title
    font-size: 18px
    font-weight: 700
    text-transform: uppercase
    color: $main-pink
    letter-spacing: 3px
    margin-bottom: 15px
    text-align: center

  &__subtitle
    font-size: 16px
    text-align: center
    color: $main-pink
    margin-bottom: 35px

  &__events
    margin-bottom: 45px

    .event
      margin-bottom: 25px

      &__title
        color: $main-pink
        margin-bottom: 10px
        font-weight: 700

      &__code
        font-size: 15px
        color: rgba(28,54,83, 0.9)
        margin-bottom: 3px

      &__colors
        font-size: 15px
        color: rgba(28,54,83, 0.7)
        margin-bottom: 7px

      &__img
        width: 130px
        margin-top: -30px
        margin-left: -10px
        margin-bottom: -30px

      &__desc
        font-size: 14px
        color: $main-pink
        line-height: 19px
