.location
  margin: 0 10px
  padding-bottom: 60px
  display: flex
  flex-direction: column
  align-items: center

  &__castle-link
    color: $main-pink
    font-weight: 600

  .embla
    &__container
      direction: rtl
    &__slide
      margin-left: unset
      margin-right: 15px
      border: 1px solid #c6c7ca

  &__airports-list
    padding-top: 20px
    padding-bottom: 20px

  &__airports-title
    font-weight: 700
    padding-bottom: 8px

  &__airport
    color: rgba(28, 54, 83, 0.7)
    font-weight: 500
