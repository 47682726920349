.party-member
  margin-bottom: 20px

  &__header
    color: rgba(28, 54, 83, 0.7)
    font-weight: 600
    padding-bottom: 10px
    display: flex
    justify-content: space-between
    align-items: center

  &__icon
    cursor: pointer
    padding: 4px
