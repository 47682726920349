.overlay-container
  display: none
  background-color: rgba(55,36,40,.88)
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 80px
  z-index: 1002

  &--visible
    display: unset

.embla-overlay
  height: 100%
  overflow: hidden

  @media ( max-width: $layout-screen  )
    height: unset

  &__close-wrapper
    position: absolute
    display: flex
    top: 88px
    right: 88px
    z-index: 2
    padding: 8px

    @media ( max-width: $layout-screen  )
      top: unset
      bottom: 88px
      right: 48%

  &__close-icon
    color: white
    cursor: pointer

  &__container
    display: flex
    align-items: center
    height: 100%
    backface-visibility: hidden

  &__slide
    display: flex
    justify-content: center
    flex: 0 0 100%
    min-width: 0
    height: 100%
    position: relative

    &-wrapper
      display: flex
      justify-content: center
      border-radius: 12px
      overflow: hidden

    img
      display: block
      width: 100%
      object-fit: cover

  &__button-wrapper
    position: absolute
    z-index: 1
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    width: 80px
    height: 100%

    &-left
      top: 0
      left: 0

    &-right
      top: 0
      right: 0

  &__button
    -webkit-appearance: none
    background-color: transparent
    text-decoration: none
    cursor: pointer
    border: 0
    padding: 0
    margin: 0
    color: white
    font-size: 24px

    svg
      width: 50px
      height: 50px
