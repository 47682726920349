.hello-there
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  background-image: url(../assets/couple/pic_1.jpg)
  background-size: cover
  background-repeat: no-repeat
  background-color: rgba(55, 36, 40, 0.50)
  background-blend-mode: multiply

  @media ( max-width: $layout-screen )
    background-position-x: -150px

  @media ( max-width: $layout-tablet )
    background-position-x: -230px
    background-image: url(../assets/hero-mobile.jpg)

  @media ( min-width: 500px ) and ( max-width: $layout-tablet)
    background-position-x: -150px

  &__logo
    width: 500px
    object-fit: cover
    margin-bottom: 15px
    @media ( max-width: $layout-tablet  )
      width: 90%

  &__image
    width: 100%
    height: 100%
    object-fit: cover
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    left: 0
    z-index: 1

    @media ( max-width: $layout-tablet  )
      width: unset
      left: -275px

  &__content-wrapper
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    z-index: 3

  &__content
    position: relative
    background-color: white
    width: 90%
    max-width: 500px
    border-radius: 13px
    box-shadow: 0px 20px 40px rgba(0,0,0,1)

  &__pass-input
    width: 100%
    padding: 14px
    font-size: 24px
    display: inline
    border: 2px solid $main-pink
    border-radius: 12px
    box-sizing: border-box
    text-align: center
    color: $main-pink

    &::placeholder
      font-weight: 400
      color: #C4C4C4

    &:focus
      outline: 2px solid $main-pink

.MuiBackdrop-root
  background-color: rgba(55,36,40,.88) !important
