.gifts
  min-width: 600px
  padding: 30px
  padding-top: 45px

  @media ( max-width: $layout-tablet  )
    min-width: unset

  &__title
    font-size: 18px
    font-weight: 700
    text-transform: uppercase
    color: $main-pink
    letter-spacing: 3px
    margin-bottom: 20px
    text-align: center

  &__subtitle
    font-size: 16px
    text-align: center
    color: $main-pink
    margin-bottom: 35px
    line-height: 23px
