.form
  width: 600px

  @media ( max-width: $layout-tablet  )
    width: 100%
    padding-top: 0

  &__more-people
    padding-top: 20px
    text-align: center
    line-height: 24px
    margin-bottom: 10px

  &__people-bold
    font-weight: 600

  &__add-people
    margin-bottom: 15px

  &__add-more
    padding-top: 20px

  &__submit
    padding-top: 15px

  &__indicator
    font-weight: 600
    margin-top: 15px
    height: 45px
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center

    &--sending
      color: #2498bf
      border: 2px solid #2498bf
      background-color: #d5efee

    &--success
      color: #21ad46
      border: 2px solid #21ad46
      background-color: #d2f7dc

    &--failure
      color: #d61402
      border: 2px solid#d61402
      background-color: #fcd5d2
