.section-header
  width: 100%
  text-align: center
  padding-top: 60px
  padding-bottom: 40px
  text-transform: uppercase
  font-size: 18px
  letter-spacing: 3px
  font-weight: 700
  line-height: 26px

  &--with-sub
    padding-bottom: 20px

  &--contained
    max-width: 1140px

.section-subheader
  width: 100%
  text-align: center
  padding-bottom: 40px
  line-height: 26px
  padding-left: 12px
  padding-right: 12px

  &--contained
    max-width: 1140px
