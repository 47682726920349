.text-field-wrapper
  width: 100%
  padding-top: 15px
  margin-bottom: 10px

  .MuiOutlinedInput-notchedOutline
    border-color: $main-pink !important
    color: $main-pink !important
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)

  .MuiInputLabel-root
    color: $main-pink !important

  .MuiOutlinedInput-input
    color: $main-pink !important

  .icon
    color: $main-pink !important

  .divider
    height: 25px
    border-right: 1px solid $main-pink
    padding-left: 15px
    margin-right: 8px
